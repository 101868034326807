import React from 'react';
import '../../components/css/common.css'
import ticket from '../../assets/images/svg/homepagetickets.png';
import octagonImage from '../../assets/images/svg/octagon.png';
import costReductionImage from '../../assets/images/svg/costReduction.png';
import performanceImage from '../../assets/images/svg/performance.png';
import icon1 from '../../assets/images/svg/icon1.png';
import icon2 from '../../assets/images/svg/icon2.png';
import icon3 from '../../assets/images/svg/icon3.png';

const HomePage = (props) => {
  return (
    <div>
      <div className="section-container">
        <div className="content-section">
          <div className="button-outline">ALERTNOWへようこそ</div>
          <h1 className="main-heading">AlertNow : 統合通知ソリューション</h1>
          <p className="text-large">AlertNowは、マルチクラウド環境の通知に効果的に対応できるインシデント統合管理プラットフォームです。</p>
          <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
          <hr className="divider" />
          <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
        </div>
        <div className="image-section">
          <img src={ticket} alt="Ticket" />
        </div>
      </div>

      <div className="centered-section">
        <div className="text-center">
          <p className='text-heading'>なぜAlertNowなのか？</p>
          <p className='text-normal'>AlertNowは、さまざまなモニタリングツールのアラートを統合し、</p>
          <p className='text-normal'>重複を排除して迅速に伝達することで、問題解決時間を短縮します。</p>
          <img src={octagonImage} alt="Octagon" className="responsive-image" />
        </div>
      </div>

      <div className="benefits-section">
        <div className="text-center">
          <p className="text-heading">コスト削減と運用効率を向上させるインサイトを得ることができます。</p>
        </div>
        <div className="benefit-item">
          <img src={costReductionImage} alt="Cost Reduction" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">運用コスト削減</h3>
            <p className="text-normal">
              AlertNowは、インシデントの発生、伝達、処理のプロセスを自動化したプラットフォームであり、少ない人員で増加するインシデントを処理できます。
              サービス運用チームの運用コストを削減しながら、インシデントを処理し解決できます。
            </p>
          </div>
        </div>

        <div className="benefit-item">
          <div className="benefit-description">
            <h3 className="text-subheading">パフォーマンス測定指標を通じた洞察の獲得、サービス障害時間の短縮</h3>
            <p className="text-normal">
            AlertNowは、障害発生時に正確な担当者に迅速に通知を伝達し、障害解決にかかる時間を大幅に短縮します。通知発生件数、平均確認時間、平均終了時間などのさまざまな指標を使用して、今後発生する障害に備えることができます。
            </p>
          </div>
          <img src={performanceImage} alt="Performance Monitoring" className="responsive-image" />
        </div>
      </div>

      <div className="automation-section">
        <h2 className="text-heading">運用の自動化と業務効率化を提供します。</h2>
        <div className="cards-container">
          <div className="card">
            <img src={icon1} alt="Automated Notifications" className="icon" />
            <h3 className="text-subheading">自動化された段階的通知</h3>
            <p className="text-normal">
            AlertNowは、問題発生時に即座に担当者に通知を送り、一定時間内に適切な対応がされない場合は次の段階の担当者に内容を伝えることで、見落とされる問題がないようにします。担当者不在の場合は、優先担当機能を通じて代替担当者を指定し、重要なインシデントに対する通知を受け取るのを逃さないようにしてビジネスを守ります。
            </p>
          </div>

          <div className="card">
            <img src={icon2} alt="Noise Reduction" className="icon" />
            <h3 className="text-subheading">通知のノイズから解放</h3>
            <p className="text-normal">
            AlertNowは3重フィルタリング機能を用いて、重複または重要でない通知を排除し、運用担当者が確認する必要のある通知の量を最大80%まで削減します。スケジュールと優先担当機能を活用して、日別または時間別に異なる担当者を指定できるため、特定の担当者に通知確認/処理業務が集中する問題を解消できます。
            </p>
          </div>

          <div className="card">
            <img src={icon3} alt="Noise Reduction Continued" className="icon" />
            <h3 className="text-subheading">ツール統合による能力強化</h3>
            <p className="text-normal">
            AlertNowは、さまざまな監視ツールで発生するアラームを統合管理できる環境を提供することで、効率的に業務を処理できます。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
