import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Toast } from 'primereact/toast';

import './ContactForm.css';

const ContactForm = ({ formType, onSubmit }) => {
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        companyName: '',
        department: '',
        name: '',
        email: '',
        phoneNumber: '',
        industry: '',
        privacyPolicy: false,
        marketingConsent: false,
        demoRequest: '',
        visitRoute: '',
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.companyName) newErrors.companyName = '会社名は必須です';
        if (!formData.department) newErrors.department = '部署は必須です';
        if (!formData.name) newErrors.name = '名前は必須です';
        if (!formData.industry) newErrors.industry = '業種は必須です';
        if (!formData.email) {
            newErrors.email = 'メールアドレスは必須です';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            newErrors.email = '無効なメールアドレスです';
        }
        if (!formData.phoneNumber) newErrors.phoneNumber = '電話番号は必須です';
        if (!formData.privacyPolicy) {
            newErrors.privacyPolicy = '個人情報の収集と利用に関する同意は必須です';
        }
        if (formType === 'introduction') {
            if (!formData.demoRequest) newErrors.demoRequest = 'デモリクエストを選択してください';
            if (!formData.visitRoute) newErrors.visitRoute = '訪問経路を選択してください';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const emailPayload = {
                source: formData.email,
                subject: `お問い合わせ from ${formData.name}`,
                body: `
                    companyName: ${formData.companyName}
                    department: ${formData.department}
                    name: ${formData.name}
                    industry: ${formData.industry}
                    phoneNumber: ${formData.phoneNumber}
                    email: ${formData.email}
                    ${formType === 'introduction' ? `demoRequest: ${formData.demoRequest}` : ''}
                    ${formType === 'introduction' ? `visitRoute: ${formData.visitRoute}` : ''}
                    privacyPolicy: ${formData.privacyPolicy}
                    marketingConsent: ${formData.marketingConsent}

                `,
            };
            try {
                const response = await axios.post('https://t1ebcgpyn1.execute-api.ap-northeast-2.amazonaws.com/prod/send-email', emailPayload);

                if (response.status === 200) {
                           toast.current.show({ severity: 'success', summary: 'Success', detail: 'Email sent successfully!' });
                    setFormData({
                        companyName: '',
                        department: '',
                        name: '',
                        email: '',
                        phoneNumber: '',
                        industry: '',
                        privacyPolicy: false,
                        marketingConsent: false,
                        demoRequest: '',
                        visitRoute: '',
                    });
                    setErrors({});
                } else {
                    toast.current.show({ severity: 'error', summary: '', detail: 'The email was not sent. Please try again later.' });
                }
            } catch (error) {
                toast.current.show({ severity: 'error', summary: '', detail: 'The email was not sent. Please try again later.' });
                console.error('Error sending email:', error);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className='contact-form'>
            <Toast ref={toast} />

            <div className="form-row">
                <div className="form-group">
                    <label htmlFor="companyName">会社名 <span>*</span></label>
                    <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={handleInputChange} />
                    {errors.companyName && <span className="error">{errors.companyName}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="department">部署 <span>*</span></label>
                    <input type="text" id="department" name="department" value={formData.department} onChange={handleInputChange} />
                    {errors.department && <span className="error">{errors.department}</span>}
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label htmlFor="name">名前 <span>*</span></label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} />
                    {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="industry">業種 <span>*</span></label>
                    <input type="text" id="industry" name="industry" value={formData.industry} onChange={handleInputChange} />
                    {errors.industry && <span className="error">{errors.industry}</span>}
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label htmlFor="email">会社のメール <span>*</span></label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">電話番号 <span>*</span></label>
                    <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
                    {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                </div>
            </div>

            {formType === 'introduction' && (
                <>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="demoRequest">デモリクエストの有無<span>*</span></label>
                            <select id="demoRequest" name="demoRequest" value={formData.demoRequest} onChange={handleInputChange}>
                                <option value="" disabled>デモ希望</option>
                                <option value="yes">はい</option>
                                <option value="no">いいえ</option>
                            </select>
                            {errors.demoRequest && <span className="error">{errors.demoRequest}</span>}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="visitRouteGroup">
                                訪問経路
                                <span>*</span>
                            </label>
                            <div className="radio-group" id="visitRouteGroup">
                                <input id="route1" name="visitRoute" type="radio" value="知人紹介" onChange={handleInputChange} />
                                <label htmlFor="route1">知人紹介</label>
                                <input id="route2" name="visitRoute" type="radio" value="メディア報道" onChange={handleInputChange} />
                                <label htmlFor="route2">メディア報道</label>
                                <input id="route3" name="visitRoute" type="radio" value="検索エンジン" onChange={handleInputChange} />
                                <label htmlFor="route3">検索エンジン</label>
                                <input id="route4" name="visitRoute" type="radio" value="YouTube" onChange={handleInputChange} />
                                <label htmlFor="route4">YouTube</label>
                                <input id="route5" name="visitRoute" type="radio" value="Facebook" onChange={handleInputChange} />
                                <label htmlFor="route5">Facebook</label>
                                <input id="route6" name="visitRoute" type="radio" value="その他" onChange={handleInputChange} />
                                <label htmlFor="route6">その他</label>
                            </div>
                            {errors.visitRoute && <span className="error">{errors.visitRoute}</span>}
                        </div>
                    </div>
                </>
            )}

            <div className="form-row form-row-last-child">
                <div className="form-group checkbox-group">
                    <input type="checkbox" id="privacyPolicy" name="privacyPolicy" checked={formData.privacyPolicy} onChange={handleInputChange} />
                    <label htmlFor="privacyPolicy">個人情報の収集と利用に関する同意 <span>*</span></label>
                </div>

                <div className="form-group checkbox-group">
                    <input type="checkbox" id="marketingConsent" name="marketingConsent" checked={formData.marketingConsent} onChange={handleInputChange} />
                    <label htmlFor="marketingConsent">マーケティングに関する情報を受け取ることに同意する</label>
                </div>
            </div>
            {errors.privacyPolicy && <span className="error">{errors.privacyPolicy}</span>}

            <div className='submit'>
                <button type="submit" className="submit-button">お問い合わせ</button>
            </div>
        </form>
    );
};

ContactForm.propTypes = {
    formType: PropTypes.oneOf(['introduction', 'contact']).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
