import React from 'react';
import './cta.css';
import ctaImage from '../../assets/images/svg/cta.png';
const CTA = (props) => {
  return (
    <div className="cta-section">
      <div className="cta-content">
        <h1 className="cta-title">全ての通知をAlertNowで簡単に一括管理！</h1>
        <p className="cta-description">
          AlertNowの強力な統合通知システムで効率的なアラームプロセスを提供します。
        </p>
        <button className="cta-button" onClick={props.inquiry}>お問い合わせ</button>
      </div>
      <div className="cta-image">
        <img src={ctaImage} alt="AlertNow illustration" />
      </div>
    </div>
  );
};

export default CTA;
