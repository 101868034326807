import React from 'react';
import '../../../components/css/common.css'
import introduction from '../../../assets/images/svg/contact/introduction.svg';
import ContactForm from '../../../components/contact/ContactForm';

const IntroductionPage = () => {
    return (
        <div>
            <div className="section-container">
                <div className="content-section">
                    <div className="button-outline">会社紹介 ALERTNOW</div>
                    <h1 className="main-heading">イントロダクション</h1>
                    <p className="text-large">AlertNowの紹介書をダウンロードできます。</p>
                    <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
                    <hr className="divider" />
                    <button className="button-primary">今すぐお問い合わせください</button>
                </div>
                <div className="image-section">
                    <img src={introduction} alt="Introduction" />
                </div>
            </div>

            <div className="form-container">
                <h1 className="form-title">AlertNow：イントロダクション</h1>
                <ContactForm formType="introduction" />
            </div>

        </div >
    );
};

export default IntroductionPage;
