import React from 'react';
import '../../components/css/common.css'
import incident from '../../assets/images/svg/features/incident/incident.svg';
import incidentList from '../../assets/images/svg/features/incident/incident-list.svg';
import incidentRecords from '../../assets/images/svg/features/incident/incident-records.svg';


const IncidentPage = (props) => {
  return (
    <div>
      <div className="section-container">
        <div className="content-section">
          <div className="button-outline">イベント - ALERTNOW</div>
          <h1 className="main-heading">インシデント</h1>
          <p className="text-large">通知統合と警告状態を一目で確認できます。</p>
          <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
          <hr className="divider" />
          <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
        </div>
        <div className="image-section">
          <img src={incident} alt="Integration" />
        </div>
      </div>

      <div className="benefits-section">
        <div className="text-center">
          <p className="text-heading">コスト削減と運用効率を向上させるインサイトを得ることができます。</p>
        </div>
        <div className="benefit-item">
          <img src={incidentList} alt="incidentList" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">インシデントリスト</h3>
            <p className="text-normal">
            指定された期間中に、日付、状態、緊急性、サービスなどのさまざまなフィルターを使用して、インシデントの発生状況を迅速に評価できます
            </p>
          </div>
        </div>

        <div className="benefit-item">
          <div className="benefit-description">
            <h3 className="text-subheading">インシデント記録の照会と変更</h3>
            <p className="text-normal">
            インシデントの状態変更、通知の伝播、コメントの作成など、さまざまな活動記録を追跡し、インシデントの状態変更や責任の割り当てなどの作業を実行できます。
            </p>
          </div>
          <img src={incidentRecords} alt="incidentRecords" className="responsive-image" />
        </div>

      </div>
    </div>
  );
};

export default IncidentPage;
