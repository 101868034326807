import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import img from '../assets/images/alertnowlogooutline.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const AppTopbar = ({ activeMenu, setActiveMenu }) => {
  const [isFeaturesOpen, setFeaturesIsOpen] = useState(false);
  const [isContactOpen, setContactIsOpen] = useState(false);

  const featuresDropdownRef = useRef(null);
  const contactDropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (featuresDropdownRef.current && !featuresDropdownRef.current.contains(event.target)) {
        setFeaturesIsOpen(false);
      }
      if (contactDropdownRef.current && !contactDropdownRef.current.contains(event.target)) {
        setContactIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const handleRedirect = () => {
    window.open('https://app.alertnow-x.jp', '_blank', 'noopener');
  };

  const handleMenuClick = (event, menu) => {
    handleDropdownClick(menu);
  };

  const handleDropdownClick = (menu) => {
    setActiveMenu(menu);
    setFeaturesIsOpen(false);
    setContactIsOpen(false);
  };

  return (
    <div className="layout-topbar">
      <div className="topbar-left">
        <img src={img} alt="Logo" className="logo" />
      </div>
      <div className="topbar-right">
        <nav>
          <ul>
            <li>
              <Link to="/home" className={activeMenu === 'Home' ? 'active' : ''} onClick={(e) => handleMenuClick(e, 'Home')}>ホーム</Link>
            </li>
            <li ref={featuresDropdownRef} className="dropdown">
              <Link to="#" className={activeMenu === 'Features' ? 'active' : ''} onClick={() => {setFeaturesIsOpen(!isFeaturesOpen); setContactIsOpen(false)}}>
              特徴 <FontAwesomeIcon icon={isFeaturesOpen ? faAngleUp : faAngleDown} />
              </Link>
              {isFeaturesOpen && (
                <div className="dropdown-content">
                  <Link to="/integration" onClick={() => handleDropdownClick('Features')}>統合</Link>
                  <Link to="/incident" onClick={() => handleDropdownClick('Features')}>インシデント</Link>
                  <Link to="/escalation" onClick={() => handleDropdownClick('Features')}>エスカレーション</Link>
                  <Link to="/schedule" onClick={() => handleDropdownClick('Features')}>スケジュール</Link>
                </div>
              )}
            </li>
            <li>
              <Link to="/cases" className={activeMenu === 'Cases' ? 'active' : ''} onClick={(e) => handleMenuClick(e, 'Cases')}>ケース</Link>
            </li>
            <li>
              <Link to="/pricing" className={activeMenu === 'Pricing' ? 'active' : ''} onClick={(e) => handleMenuClick(e, 'Pricing')}>価格設定</Link>
            </li>
            <li ref={contactDropdownRef} className="dropdown">
              <Link to="#" className={activeMenu === 'Contact' ? 'active' : ''} onClick={() => {setContactIsOpen(!isContactOpen); setFeaturesIsOpen(false)}}>
              お問い合わせ <FontAwesomeIcon icon={isContactOpen ? faAngleUp : faAngleDown} />
              </Link>
              {isContactOpen && (
                <div className="dropdown-content">
                  <Link to="/inquiry" onClick={() => handleDropdownClick('Contact')}>お問い合わせ</Link>
                  <Link to="/introduction" onClick={() => handleDropdownClick('Contact')}>会社紹介</Link>
                </div>
              )}
            </li>
          </ul>
        </nav>
        <button className="login-button" onClick={handleRedirect}>ログイン</button>
      </div>
    </div>
  );
};

export default AppTopbar;
