import React from 'react';
import '../../components/css/common.css'
import pricing from '../../assets/images/svg/pricing/pricing.svg';
import './PricingPage.css'

const PricingPage = (props) => {
    return (
        <div>
            <div className="section-container">
                <div className="content-section">
                    <div className="button-outline">価格設定</div>
                    <h1 className="main-heading">価格</h1>
                    <p className="text-large">AlertNowをお得な価格でご利用いただけます！</p>
                    <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
                    <hr className="divider w-100" />
                    <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
                </div>
                <div className="image-section">
                    <img src={pricing} alt="Pricing" />
                </div>
            </div>
            <div className="pricing-container">
                <h1 className="pricing-title">AlertNowについてお問い合わせいただければ、</h1>
                <div className="pricing-options">

                    <div className="pricing-card standard-plan">
                        <h2>スタンダード/月</h2>
                        <hr className="divider w-100" />
                        <ul>
                            <li>ユーザーあたり $14</li>
                            <li>最大ユーザー数: 5名まで</li>
                            <li>メール通知: 無制限</li>
                            <li>SMS/カカオトーク通知: 最大100件超過時は件ごとに追加料金）</li>
                            <li>APIリクエスト: 無制限</li>
                            <li>データ保管期間: 最近1年分</li>
                        </ul>
                        <div className='align-bottom'>
                            <div className='text-center'>
                                <p>チームベースの組織および可視性、モニタリングの統合、エスカレーション、変更履歴、通知履歴、個人設定などを提供</p>
                            </div>
                            <div className="button-container">
                                <button className="contact-button" onClick={props.inquiry}>AlertNowスタンダードのお問い合わせ</button>
                            </div>
                        </div>

                    </div>

                    <div className="pricing-card premium-plan">
                        <h2>プレミアム/月</h2>
                        <hr className="divider w-100 " />
                        <ul>
                            <li>ユーザーあたりの課金（詳細金額は協議）</li>
                            <li>ユーザー数: 無制限</li>
                            <li>メール通知: 無制限</li>
                            <li>SMS/カカオトーク通知: 無制限</li>
                            <li>音声通話通知: 無制限</li>
                            <li>AlertNowモバイルアプリ提供</li>
                            <li>APIリクエスト: 無制限</li>
                            <li>データ保存: 無制限</li>
                            <li>地域番号提供（韓国固有番号）</li>
                        </ul>
                        <div className='align-bottom'>
                            <div className='text-center'>
                                <p>デフォルト+スケジュール、エクステンション、通知テンプレート、システムレポート、インシデントレポート、通知レポート</p>
                            </div>
                            <div className="button-container">
                                <button className="contact-button" onClick={props.inquiry}>AlertNowプレミアムのお問い合わせ</button>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="contact-info">AlertNowの専門担当が迅速に対応いたします。</div>
                <button className="final-contact-button" onClick={props.inquiry}>お問い合わせ</button>
            </div>
        </div >
    );
};

export default PricingPage;
