import React from 'react';
import '../../../components/css/common.css';
import inquiry from '../../../assets/images/svg/contact/inquiry.svg';
import ContactForm from '../../../components/contact/ContactForm';

const InquiryPage = () => {
    return (
        <div>
            <div className="section-container">
                <div className="content-section">
                    <div className="button-outline">お問い合わせ ALERTNOW</div>
                    <h1 className="main-heading">お問い合わせ</h1>
                    <p className="text-large">AlertNowついてなんでも聞いてください。</p>
                    <p className="text-large">専門担当が迅速に対応いたします。</p>
                    <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
                    <hr className="divider" />
                    <button className="button-primary">お問い合わせの際はメールアドレスをご入力ください</button>
                </div>
                <div className="image-section">
                    <img src={inquiry} alt="Inquiry" />
                </div>
            </div>

            <div className="form-container">
                <h1 className="form-title">AlertNow：お問い合わせ</h1>
                <ContactForm formType="inquiry" />
            </div>
        </div>
    );
};

export default InquiryPage;
