import React from 'react';
import '../../components/css/common.css'
import escalation from '../../assets/images/svg/features/escalation/escalation.svg';
import endCondition from '../../assets/images/svg/features/escalation/end-condition.svg';
import configuration from '../../assets/images/svg/features/escalation/configuration.svg';
import notice from '../../assets/images/svg/features/escalation/notice.svg';


const EscalationPage = (props) => {
  return (
    <div>
      <div className="section-container">
        <div className="content-section">
          <div className="button-outline">段階的拡大 - ALERTNOW</div>
          <h1 className="main-heading">エスカレーション</h1>
          <p className="text-large">見逃さず、段階的に正確に通知を伝播します。</p>
          <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
          <hr className="divider" />
          <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
        </div>
        <div className="image-section">
          <img src={escalation} alt="Ecalation" />
        </div>
      </div>

      <div className="benefits-section">
        <div className="text-center">
          <p className="text-heading">コスト削減と運用効率を向上させるインサイトを得ることができます</p>
        </div>
        <div className="benefit-item">
          <img src={endCondition} alt="End Condition" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">終了条件の指定</h3>
            <p className="text-normal">
            従来混在して使用されていたモニタリングツールを統合し、ユーザーの利便性を高めました。
            </p>
          </div>
        </div>

        <div className="benefit-item">
          <div className="benefit-description">
            <h3 className="text-subheading">段階的受信者構成オプション</h3>
            <p className="text-normal">
            各段階ごとに応答者を設定でき、ユーザー、スケジュール、またはチーム（全体/任意）単位で設定できます。            </p>
          </div>
          <img src={configuration} alt="Configuration" className="responsive-image" />
        </div>

        <div className="benefit-item">
          <img src={notice} alt="Notice" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">最終通知</h3>
            <p className="text-normal">
            全エスカレーションプロセスが終了した後、終了条件が満たされていない場合、すべての回答者に通知を送信する機能を提供します。            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EscalationPage;
