import React from 'react';
import '../../components/css/common.css'
import cases from '../../assets/images/svg/cases/cases.svg';
import companies from '../../assets/images/svg/cases/companies.svg';
import CarouselComponent from '../../components/carousel/carousel';
import './CasesPage.css'

const CasesPage = (props) => {
    return (
        <div>
            <div className="section-container">
                <div className="content-section">
                    <div className="button-outline">顧客ケース ALERTNOW</div>
                    <h1 className="main-heading">顧客ケース</h1>
                    <p className="text-large">AlertNowで問題を解決した顧客の成功事例をご確認ください。</p>
                    <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
                    <hr className="divider" />
                    <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
                </div>
                <div className="image-section">
                    <img src={cases} alt="Cases" />
                </div>
            </div>
            <div className="text-center">
                <p className="text-heading mb-0">5,000以上の企業で活用されている</p>
                <p className="text-heading mt-0">AlertNowの成功事例をご確認ください。</p>
                <div className="image-section">
                    <img src={companies} alt="Companies" className='width-80per' />
                </div>
            </div>
           <CarouselComponent/>
        </div >
    );
};

export default CasesPage;
