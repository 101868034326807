import React, { useState } from 'react';
import Slider from 'react-slick';
import './carousel.css';
import samsung from '../../assets/images/png/carousel/samsung.png';
import liiv from '../../assets/images/png/carousel/liiv.png';
import olive from '../../assets/images/png/carousel/olive.png';
import shinsegae from '../../assets/images/png/carousel/shinsegae.png';
import zigbang from '../../assets/images/png/carousel/zigbang.png';
import daekyo from '../../assets/images/png/carousel/daekyo.png';

const categories = [
    "IT製造業",
    "金融業",
    "流通業",
    "クラウド産業",
    "仲介業",
    "教育産業",
];

const titles = [
    "サムスンが効率的な通知システムを構築した方法",
    "安定したサービス運営のためのリブメイトの選択",
    "サービス効率性を向上させるためのオリーブヤングの事例",
    "大企業のITサービスおよびクラウド運営のための新世界アイエンシーの事例",
    "不動産仲介業のサービスを効率的に運営するための選択",
    "AlertNowのルーティングルールを活用した大教C&Sの事例"
];

const descriptions = [
    "サムスンはグローバル事業を運営し、24時間クラウド関連の通知をモニタリングしています。そのために、24/7の問題通知システムを導入し、既存のシステムと統合しました。これにより、人件費を20%削減し、運営スタッフの業務効率を30%以上改善しました。AlertNowを通じて、問題は平均3秒以内に担当者に通知され、欠席時には10分以内に自動的にエスカレーションされます。また、ノイズフィルタリング技術を適用し、不必要な通知を40%以上削減し、障害対応時間を20%短縮しました。カスタマイズされた通知設定により、各部門が必要な情報だけを受け取れるよう最適化し、業務生産性を大幅に向上させました",
    "リブメイトは安定したサービス運営のためにAlertNowを導入し、緊急度に応じて通知の種類を差別化しました。「非常に高い」レベルのインシデントは95%以上がボイスコールで2分以内に通知され、「普通」レベルのインシデントはKakao Talk通知で平均5分以内に処理されます。この自動化システムは人件費を15%削減し、不必要な通知を30%以上ブロックするノイズフィルタリングを適用して、実質的なインシデント対応率を15%向上させました。また、組織ごとにカスタマイズされた通知を設定し、各部門が迅速に対応できるようにし、セキュリティや規制要件を満たすカスタム通知ルールを設定して、規制遵守と運営効率を強化しました。",
    "オリーブヤングはサービス運営の効率性を高めるために、SaaS型のAlertNowを活用してインシデントをサービス別に管理しています。各ツールで発生したインシデントは、顧客の内部Slackチャンネルにリアルタイムで通知され、平均3分以内に対応できるように構築されています。重要なインシデントは90%以上が1分以内にボイスコールで通知され、重要度の低い通知はEmailで管理することで通知疲労度を25%減少させました。この結果、人件費を18%削減し、重要な問題への対応時間を平均15%短縮しました。また、組織ごとに問題を効果的に通知するために、サービス別に通知を細分化し、関連チームが適時に対応できるようになりました。",
    "新世界アイエンシーはKubernetesベースのサービスをクラスター単位でグループ化し、SaaS型のAlertNowを通じて管理しています。各クラスターを担当するチームはAlertNowのチーム機能を活用し、インシデント発生時に90%以上が5分以内に対応できるようにしています。ノイズフィルタリング技術を適用し、不必要な通知を35%減少させ、これにより人件費を20%削減しました。サービス運営の安定性は20%向上し、全体のインシデント対応時間は25%短縮されました。また、チームごとにクラスター関連の通知を細分化し、各チームが必要な情報を即座に受け取れるよう設定することで、組織ごとに効果的な問題対応が可能になりました。",
    "ジクバンは主要サービスをAlertNowにモジュール単位で細分化して登録し、フロントエンドとバックエンドにグループ化することでインシデントの可視性を40%以上改善しました。各モジュールで発生したインシデントは平均3分以内に把握され、迅速に対応できます。また、エスカレーショングループを通じて職階別に通知が10分以内に伝達されます。ノイズフィルタリング技術を適用し、不必要な通知を30%減少させ、人件費を15%削減し、重要な通知への対応率が20%向上しました。さらに、職階別にカスタマイズされた通知システムを強化し、各職階に適した重要な通知が適時に伝達されるように設定し、組織内の問題対応を最適化しました。",
    "大教C&Sは不必要なノイズ性通知を効果的に遮断するためにAlertNowのルーティングルールを活用し、通知を40%減少させました。これにより運営チームの通知疲労度を軽減し、重要なインシデントに集中できるようになりました。また、OPEN APIを通じてインシデント活動履歴データを内部管理データとして活用し、データ分析の正確度を25%向上させ、全体的なインシデント対応効率を30%改善しました。これらの改善により人件費を20%削減し、組織別に問題を効果的に伝えるためにルーティングルールを細分化し、特定の部門が担当する問題のみを選別して通知を受け取るよう最適化しました。"
];

const images = [
    samsung,
    liiv,
    olive,
    shinsegae,
    zigbang,
    daekyo
];

const CarouselComponent = () => {
    const [selectedCategory, setSelectedCategory] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (current, next) => {
            setSelectedCategory(next);
        },

    };

    return (
        <div className="carousel-container">
            <div className='text-center'>
                <h1 className="text-heading">詳細な事例</h1>
            </div>
            <div className="categories">
                {categories.map((category, index) => (
                    <span key={index} className={`category ${selectedCategory === index ? 'active' : ''}`}>
                        {category}
                    </span>
                ))}
            </div>
            <Slider {...settings}>
                {descriptions.map((description, index) => (
                    <div key={index} className="slider-item">
                        <img src={images[index]} alt={`Slide ${index + 1}`} className="slider-image" />
                        <h2 className="brand">{titles[index]}</h2>
                        <p className="description">{description}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CarouselComponent;
