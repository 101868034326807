import React, { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import IntegrationPage from '../pages/Features/IntegrationPage';
import IncidentPage from '../pages/Features/IncidentPage';
import EscalationPage from '../pages/Features/EscalationPage';
import SchedulePage from '../pages/Features/SchedulePage';
import CasesPage from '../pages/Cases/CasesPage';
import PricingPage from '../pages/Pricing/PricingPage';
import InquiryPage from '../pages/Contact/Inquiry/InquiryPage';
import IntroductionPage from '../pages/Contact/Introduction/IntroductionPage';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import CTA from '../components/cta/cta';



function App() {
  const containerClassName = classNames('layout-wrapper');
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('Home');
  useEffect(() => {
    navigate('/home'); 
  },[]);
  const inquiry=()=>{
    navigate('/inquiry'); 
    setActiveMenu('Contact');
  }



  return (
    <div className={containerClassName}>
      <div className="layout-content-wrapper">
        <AppTopbar activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <div className="layout-sidebar">

        </div>
        <div className="layout-content">
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage inquiry={inquiry}/>} />
            <Route path="/integration" element={<IntegrationPage inquiry={inquiry}/>} />
            <Route path="/incident" element={<IncidentPage inquiry={inquiry}/>} />
            <Route path="/escalation" element={<EscalationPage inquiry={inquiry}/>} />
            <Route path="/schedule" element={<SchedulePage inquiry={inquiry}/>} />
            <Route path="/cases" element={<CasesPage inquiry={inquiry}/>} />
            <Route path="/pricing" element={<PricingPage inquiry={inquiry}/>} />
            <Route path="/inquiry" element={<InquiryPage />} />
            <Route path="/introduction" element={<IntroductionPage />} />

          </Routes>
        </div>
        <CTA inquiry={inquiry}/>
        <AppFooter />
      </div>
    </div >
  );
}

export default App;
