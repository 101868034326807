import React from 'react';
import '../../components/css/common.css'
import schedule from '../../assets/images/svg/features/schedule/schedule.svg';
import rotation from '../../assets/images/svg/features/schedule/rotation.svg';
import multipleTiers from '../../assets/images/svg/features/schedule/multiple-tiers.svg';
import notice from '../../assets/images/svg/features/schedule/mobile-notices.svg';


const SchedulePage = (props) => {
  return (
    <div>
      <div className="section-container">
        <div className="content-section">
          <div className="button-outline">スケジュール - ALERTNOW</div>
          <h1 className="main-heading">スケジュール</h1>
          <p className="text-large">スケジュールに応じて通知受信者を自動指定</p>
          <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
          <hr className="divider" />
          <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
        </div>
        <div className="image-section">
          <img src={schedule} alt="Schedule" />
        </div>
      </div>

      <div className="benefits-section">
        <div className="text-center">
          <p className="text-heading">コスト削減と運用効率を向上させるインサイトを得ることができます。</p>
        </div>
        <div className="benefit-item">
          <img src={rotation} alt="Rotation" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">時間/ローテーション別受信者設定</h3>
            <p className="text-normal">
              タイムゾーンごとに担当者を指定したり、毎日/毎週/ユーザー指定の間隔で通知受信のローテーション周期を設定できます。            </p>
          </div>
        </div>

        <div className="benefit-item">
          <div className="benefit-description">
            <h3 className="text-subheading">多層的アプローチ</h3>
            <p className="text-normal">
              複数の層を構成することで、同じタイムゾーンで複数のチームメンバーが同時に通知を受け取ることができます。           </p>
          </div>
          <img src={multipleTiers} alt="Multiple Tiers" className="responsive-image" />
        </div>

        <div className="benefit-item">
          <img src={notice} alt="Notice" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">最終通知</h3>
            <p className="text-normal">
              全エスカレーションプロセスが終了した後、終了条件が満たされていない場合、すべての回答者に通知を送信する機能を提供します。            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulePage;
