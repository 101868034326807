import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <div className="footer-left">
                <div >© 2024 AlertNow. All rights reserved.</div>
            </div>
            <div className="footer-right">
                <div>Terms & Conditions</div>
                <div>Privacy Policy</div>
            </div>
        </div>
    );
}
export default AppFooter;
