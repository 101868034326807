import React from 'react';
import '../../components/css/common.css'
import integration from '../../assets/images/svg/features/integration/integration.svg';
import network from '../../assets/images/svg/features/integration/network.svg';
import routing from '../../assets/images/svg/features/integration/routing.svg';
import continuousintegration from '../../assets/images/svg/features/integration/continuousintegration.svg';


const IntegrationPage = (props) => {
  return (
    <div>
      <div className="section-container">
        <div className="content-section">
          <div className="button-outline">統合 - ALERTNOW</div>
          <h1 className="main-heading">インテグレーション</h1>
          <p className="text-large">さまざまなツールの通知を一つにまとめます。</p>
          <p className="text-normal color-white">お問い合わせの際はメールアドレスをご入力ください</p>
          <hr className="divider" />
          <button className="button-primary" onClick={props.inquiry}>今すぐお問い合わせください</button>
        </div>
        <div className="image-section">
          <img src={integration} alt="Integration" />
        </div>
      </div>

      <div className="benefits-section">
        <div className="text-center">
          <p className="text-heading">コスト削減と運用効率を向上させるインサイトを得ることができます。</p>
        </div>
        <div className="benefit-item">
          <img src={network} alt="network" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">通知統合</h3>
            <p className="text-normal">
              既存の混在して使用されていた監視ツールを統合し、ユーザーの利便性を向上させました。
            </p>
          </div>
        </div>

        <div className="benefit-item">
          <div className="benefit-description">
            <h3 className="text-subheading">マルチルーティング</h3>
            <p className="text-normal">
              メトリックの名前または要約機能を使用して、サービスレベルでインシデントを管理し、それに応じた条件を設定できます。
            </p>
          </div>
          <img src={routing} alt="routing" className="responsive-image" />
        </div>

        <div className="benefit-item">
          <img src={continuousintegration} alt="Continuous Integration" className="responsive-image" />
          <div className="benefit-description">
            <h3 className="text-subheading">継続的インテグレーション</h3>
            <p className="text-normal">
              AlertNowでは30以上の連携機能を提供しています。お客様のリクエストに応じて機能を継続的に統合しています。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;
